'use strict';

angular.module('enervexSalesappApp').controller('ApplianceModelCtrl', function($scope, ApplianceCompany, $stateParams, Flash, $state, _, ApplianceModel, Application, Fuel, Icon, Product, ProductSubtype, Util, Manufacturer) {
	$scope.errors = {};
	$scope.ctx = {
		formChanged: false
	}

	Manufacturer.query().$promise.then(function(result){
		$scope.manufacturers = result
		$scope.manufacturer = _.find(result, function(o){
			return o.isDefault
		})
	})
	if ($stateParams.id == "new") {
		$scope.item = {
			name: "",
			sortOrder: 0
		}
		fetchDeps()
	} else {
		ApplianceModel.get({
			id: $stateParams.id
		}).$promise.then(function(item) {
			item.icon = item.icon ? item.icon._id : null
			$scope.item = item;
			$scope.ctx.startingPointProductNavId = $scope.item.startingPointProduct && $scope.item.startingPointProduct.navId
			fetchDeps()
		})
	}
	function fetchDeps() {
		ApplianceCompany.get({
			id: $stateParams.applianceCompanyId
		}).$promise.then(function(item) {
			$scope.applianceCompany = item;
			$scope.item.applianceCompany = item;
		})
		Application.query().$promise.then(function(applications){
			$scope.applications = _.map(applications, function(application){
				var existing = _.find($scope.item.applications, function(o){
					return o._id == application._id
				})
				if (existing){
					application.selected = true
				}
				return application
			})
		})
		Fuel.query().$promise.then(function(fuels){
			$scope.fuels = _.map(fuels, function(fuel){
				var existing = _.find($scope.item.fuels, function(o){
					return o.fuel._id == fuel._id
				})
				if (existing){
					return existing
				} else {
					return {
						fuel: fuel,
						include: false,
						co2:0,
						co2min: 0,
						mbhmin: 0,
						mbhmax: 0,
						temp: 0,
						tempmin: 0,
						ratedEfficiency: 0
					}
				}
			})
		});
		ProductSubtype.query().$promise.then(function(result){
			$scope.allProductSubtypes = result;
			$scope.productSubtypes = Util.getSubtypes("StartingPoint", $scope.allProductSubtypes, $scope.design)
		})
		Icon.query().$promise.then(function(icons){
			$scope.icons = icons;
			console.log("icons", icons)
		})
		$scope.cats = [
			"II",
			"I - Direct Connect",
			"Please Select",
			"IV", 
			"Oil", 
			"III", 
			"I - Draft Hood",
			"I - Fan Assisted"
		]
		$scope.startingConfigs = [
			"type",
			"product"
		]
		$scope.computeAses = [
			"BWH",
			"DRY"
		]
		$scope.startingPointTypes = Util.getSubtypes("StartingPoint", $scope.allProductSubtypes,$scope.design)
	}

	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.item);
			payload = _.omit(payload, "__v");
			payload.applications = _.pluck(_.where($scope.applications, {selected:true}), "_id")
			payload.fuels = $scope.fuels;
			if (!$scope.item._id) {
				ApplianceModel.save(payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully created model.', 0, {}, true);
					$state.go("appliance-model", {
						id: result._id,
						applianceCompanyId: $stateParams.applianceCompanyId
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				ApplianceModel.update({
					id: $scope.item._id
				}, payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully updated model.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
	$scope.autoCompleteOptions = {
		minimumChars: 1,
		dropdownWidth: "500px",
		data: function (term) {
			return Product.query({
				limit:20,
				term: term,
				manufacturer: $scope.manufacturer._id
			}).$promise.then(function(products){
				$scope.productsPage = products
				return _.map(products, function(product){
					return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
				});
			})
		},
		itemSelected: function(item){
			$scope.ctx.formChanged = true;
			var fullProduct = _.find($scope.productsPage, function(product){
				return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
			})
			$scope.ctx.startingPointProductNavId = fullProduct.navId
			$scope.item.startingPointProduct = fullProduct;
		}
	}

});
